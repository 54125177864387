<script setup lang="ts">
import { ref, computed } from 'vue'

import { getGlobalTranslation } from '~/js/composables/translations'
import { getClasses } from '~/js/composables/classes'

interface Props {
    color: string,
    content: string,
    utilities?: string
}

const props = withDefaults(defineProps<Props>(), {utilities: ''})

const style = ref({
    blue: {
        text: 'text-badge-text',
        background: 'bg-badge-blue',
    },
    yellow: {
        text: 'text-purple-600',
        background: 'bg-yellow-600',
    },
    green: {
        text: 'text-badge-text',
        background: 'bg-badge-green',
    },
    white: {
        text: 'text-badge-text',
        background: 'bg-purple-100',
    },
    purple: {
        text: 'text-yellow-600',
        background: 'bg-purple-600',
    },
    pink: {
        text: 'text-badge-text',
        background: 'bg-badge-pink',
    },
    light: {
        text: 'text-badge-text',
        background: 'bg-badge-yellow',
    },
})
const classes = ref({
    _default: '',
    font: 'font-primary text-sm font-bold uppercase',
    radius: 'rounded-full',
    spacing: 'px-4 pt-2 pb-1',
    width: 'w-fit',
})


const theme = computed(() => {
    return style.value[props.color] ?? style.value['blue']
})
</script>
<template>
    <span 
        :class="[
            getClasses(classes),
            theme.text,
            theme.background,
            utilities, 
        ]"
    >
        {{ content }}
    </span>
</template>